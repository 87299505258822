import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Thank from './Thank';
import ReviewDoc from './ReviewDoc';
import { useTranslation } from 'react-i18next';


function Copyright() {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t("Copyright")}

      {' © '}
      <Link color="inherit" href="https://www.linet.org.il/">
        {t("LinetSoftwareSoultions")}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({

  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width:'100%'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    width:'100%'

  },
}));

const steps = ['ReviewDoc', 'PaymentDetails', 'Confirmation'];


const currency_symbol=(name)=>{
  var currency_symbols = {
      'USD': '$', // US Dollar
      'EUR': '€', // Euro
      'CRC': '₡', // Costa Rican Colón
      'GBP': '£', // British Pound Sterling
      'ILS': '₪', // Israeli New Sheqel
      'INR': '₹', // Indian Rupee
      'JPY': '¥', // Japanese Yen
      'KRW': '₩', // South Korean Won
      'NGN': '₦', // Nigerian Naira
      'PHP': '₱', // Philippine Peso
      'PLN': 'zł', // Polish Zloty
      'PYG': '₲', // Paraguayan Guarani
      'THB': '฿', // Thai Baht
      'UAH': '₴', // Ukrainian Hryvnia
      'VND': '₫', // Vietnamese Dong
  };


  if(currency_symbols[name]) {
      return currency_symbols[name];
  }
  return name
}


function getStepContent(setActiveStep,step,data) {

  switch (step) {
    case 0:
      return <ReviewDoc data={data}/>;
    case 1:
      return <PaymentForm data={data} setActiveStep={setActiveStep} />;
    case 2:
      return <Thank />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout({data}) {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const dType = (doc) => {
    return t("DocType"+doc.doctype)
  };

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div style={{ textAlign:"center" }}>
            <img alt="logo" src={"https://app.linet.org.il/download/"+data.company_logo} width="150" />
          </div>
          <Typography component="h1" variant="h4" align="center">
            {t("PayDocument")} {dType(data.jsond_doc)} #{data.jsond_doc.docnum}
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length-1 ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  {t("ThankYouForYourOrder")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("WeHaveEmailedYouConfirmation")}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(setActiveStep,activeStep,data)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button
                    size="large"
                    onClick={handleBack}
                    className={classes.button}>
                      {t("Back")}
                    </Button>
                  )}
                    {activeStep !== 1&& <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleNext}
                      className={classes.button}
                    >
                    {activeStep === 0 && t("Pay")+" "+data.jsond_doc.total+" "+currency_symbol(data.jsond_doc.currency_id) }
                  </Button>}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
