import React,{Suspense, useState, useEffect} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';

import './App.css';
import Checkout from './Checkout';
import { DispatchContext,useChangeTheme } from './components/ThemeContext';


//import { loadCSS } from 'fg-loadcss/src/loadCSS';




//import { ThemeProvider } from './components/ThemeContext';
/*
import Avatar from '@material-ui/core/Avatar';

import { StylesProvider, jssPreset } from '@material-ui/styles';

import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Tooltip from '@material-ui/core/Tooltip';
import FormatTextdirectionLToR from '@material-ui/icons/FormatTextdirectionLToR';
import FormatTextdirectionRToL from '@material-ui/icons/FormatTextdirectionRToL';
import IconButton from '@material-ui/core/IconButton';


import { withStyles, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

*/

//import { useChangeTheme } from './components/ThemeContext';


//const LinFrame = React.lazy(() => import('./components/LinFrame'));




/*
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    //backgroundColor: theme.palette.background.level1,
    backgroundColor: theme.palette.background.default
    //backgroundColor: 'red',
  },
})
)
*/
let dependenciesLoaded = false


function LightenDarkenColor(col, amt) {
  //console.log(col)
  col = parseInt(col.replace("#",''), 16);
  //console.log(col)

  return (((col & 0x0000FF) + amt) | ((((col >> 8) & 0x00FF) + amt) << 8) | (((col >> 16) + amt) << 16)).toString(16);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },


    appBar: {
      //position: 'relative',
      height: "236px",

      //small height: "173px"
      zIndex: -1,
      position: 'absolute',
      top: 0,
      width: "100%",
      background: "linear-gradient(0deg, #" + LightenDarkenColor(theme.palette.primary.main, -10) + " , " + theme.palette.primary.main + ")"
    },

    appLogo:{
      textAlign: "center",
      paddingTop: "24px",
      paddingBottom: "15px",
      zIndex: 0
    },



}));



function loadDependencies() {
  if (dependenciesLoaded) {
    return;
  }

  dependenciesLoaded = true;

  /*loadCSS(
    'https://fonts.googleapis.com/icon?family=Material+Icons',
    document.querySelector('#material-icon-font'),
  );*/

}

function AppWrapper(props) {
  const { children, } = props;

  React.useEffect(() => {
    loadDependencies();
    //registerServiceWorker();
  }, []);

  return (<React.Fragment>{children}</React.Fragment>);
}

function isLocalNetwork (){
  let hostname = window.location.hostname
  return (
    ([
      'localhost', '127.0.0.1', '', '::1'].includes(hostname))
    || (hostname.startsWith('127.'))
    || (hostname.startsWith('192.168.'))
    || (hostname.startsWith('172.22.'))//172.16.0.0 – 172.31.255.255
    || (hostname.startsWith('10.'))
    || (hostname.endsWith('.local'))
  )
}

function App() {


    const changeTheme = useChangeTheme();
    const dispatch = React.useContext(DispatchContext);

    const { i18n } = useTranslation();



  const [data, setData] = useState(false);
  useEffect(async () => {

   let id=window.location.pathname


   if(id!==''){
     let server='app.linet.org.il'
     if(isLocalNetwork()){
       server='dev.linet.org.il'
     }
     const result = await axios(
       'https://'+server+'/paymentreq/load'+id,
     );
     //console.log(result.data)
     setData(result.data);

     if(result.data.jsond_doc.language !== 'he_il' ){
       changeTheme({ direction:  'ltr' });

     }


     i18n.changeLanguage(result.data.jsond_doc.language);



     if(result.data.primary_color && result.data.secondary_color){
       const paletteColors = {
         primary: { main: result.data.primary_color },
         secondary: { main: result.data.secondary_color	},
       };

       dispatch({
         type: 'CHANGE',
         payload: { paletteColors },
       });
     }else{
      
     }

   }
 },[]);


  /*
  const theme = useTheme();

  const changeTheme = useChangeTheme();
  const handleTogglePaletteType = () => {
    const paletteType = theme.palette.type === 'light' ? 'dark' : 'light';

    changeTheme({ paletteType });
  };
  const handleToggleDirection = () => {
    changeTheme({ direction: theme.direction === 'ltr' ? 'rtl' : 'ltr' });
  };
  const classes=useStyles()
  const { loading } = useAuth0();
  if (loading) {
    return <div>Loading </div>;
  }
  */

  const classes = useStyles();
  return (
    <AppWrapper>
      <div className={classes.appBar} />
      <div  className={classes.appLogo}>
        <img src="/logo.png" alt={"Linet Pay"} />
      </div>
      {!data && <Paper className={classes.paper}>Loading Data...</Paper>}
      {data && !data.jsond_doc && <Paper className={classes.paper}>Load Faild</Paper>}
      {data && data.jsond_doc && data.jsond_doc.paid &&<Paper className={classes.paper}>Paid</Paper>}

      {data && data.jsond_doc && !data.jsond_doc.paid &&<Checkout data={data} />}
    </AppWrapper>
  );
}
export default App
