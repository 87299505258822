import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import { ThemeProvider } from './components/ThemeContext';

import he_il from "./translations/he_il.json";
import en_us from "./translations/en_us.json";


const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: process.browser ? document.querySelector('#insertion-point-jss') : null,
});


i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'he_il',
    resources: {
          he_il: {translation:he_il},
          en_us: {translation:en_us},

        },
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider jss={jss}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </I18nextProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
