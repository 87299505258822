import React,{useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export default function PaymentForm({data,setActiveStep}) {

  useEffect(() => {

   window.addEventListener("message", handleMsg, false);
   return ()=>{
     window.removeEventListener("message", handleMsg, false);

   }
 },[]);


  const handleMsg=(msg)=>{
    console.log(msg.data)

    try {
      let data=JSON.parse(msg.data)
      if(data.code && data.code==='000'){
        setActiveStep(2)
      }   } catch(e) {
       //alert(e); // error in the above string (in this case, yes)!
   }

  }
  return (
    <React.Fragment>
          <iframe
            id="iframe"
            width="100%"
            height="475px"
            src={data.iframe_url}
            scrolling="no"
            frameborder="no"
            onMessage={handleMsg}
          ></iframe>
    </React.Fragment>
  );
}
