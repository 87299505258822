import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
    textAlign:'center'
  },
  custemer: {
    textAlign:'center'
  },
}));

export default function ReviewDoc({data}) {
  const classes = useStyles();
  const { t } = useTranslation();
  /*
  <Typography gutterBottom>{data.jsond_doc.city} {data.jsond_doc.address} {data.jsond_doc.zip}</Typography>

  <List disablePadding>
  {data.jsond_doc.docDetailes.map((line) => (
    <ListItem className={classes.listItem} key={line.name}>
      <ListItemText primary={line.name} secondary={line.description} />
      <Typography variant="body2">{line.iItem} X {line.qty} = {line.iTotalVat}</Typography>
    </ListItem>
  ))}
    <ListItem className={classes.listItem}>
      <ListItemText primary={t("Total")} />
      <Typography variant="subtitle1" className={classes.total}>
        {data.jsond_doc.total}
      </Typography>
    </ListItem>
  </List>
  */

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {t("Hello")}
          </Typography>
          <Typography className={classes.custemer} gutterBottom>{data.jsond_doc.company}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
